<template>
  <router-view />
</template>

<script>
// import './assets/css/nucleo-icons.css'
import { mapMutations } from 'vuex'

export default {
  name: 'pageAPP',
  data () {
    return {}
  },
  mounted () {
    if (localStorage.getItem('token')) {
      this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
      this.axios.get(this.$store.state.url + 'user', this.form).then((res) => {
        this.SETUSER(res.data.user)
        this.SETLOGIN(1)
      }, () => {
        localStorage.removeItem('token')
        this.SETLOGIN(0)
        this.SETUSER('')
        this.$router.replace('/login')
      })
    } else {
      this.SETLOGIN(0)
      this.SETUSER('')
      this.$router.replace('/login')
    }
  },
  methods: {
    ...mapMutations([
      'SETLOGIN',
      'SETUSER'
    ]),
    logout () {
      this.axios.post(this.$store.state.url + 'logout').then(() => {
        this.axios.defaults.headers.common['Authorization'] = ''
        localStorage.removeItem('token')
        this.SETLOGIN(0)
        this.SETUSER('')
        this.$router.push('/login')
      }, () => {
        this.axios.defaults.headers.common['Authorization'] = ''
        localStorage.removeItem('token')
        this.SETLOGIN(0)
        this.SETUSER('')
        this.$router.push('/login')
      })
    }
  }
}
</script>

<style  lang="scss">
  :root {
    --popper-theme-background-color: #ffffff;
    --popper-theme-background-color-hover: #ffffff;
    --popper-theme-text-color: #333333;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-color: #eeeeee;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 32px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>
