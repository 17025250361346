<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-center">
      <div class="copyright text-center">
        Analytics by Kaizen © 2024
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
