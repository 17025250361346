import { createRouter, createWebHistory } from "vue-router";
import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import LoginLayout from "@/layout/dashboard/LoginLayout.vue";
import NotFound from "@/views/NotFoundPage.vue";
const Gantt = () => import("@/views/Gantt.vue");
const List = () => import("@/views/List.vue");
const Login = () => import("@/views/Login.vue");

const routes = [
  {
    path: "/",
    redirect: "/gantt",
  },
  {
    path: "/gantt",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "gantt",
        component: Gantt,
        meta: {
          title: "Gantt",
        },
      },
    ],
  },
  {
    path: "/list",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "list",
        component: List,
        meta: {
          title: "List",
        },
      },
    ],
  },
  {
    path: "/login",
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "login",
        component: Login,
        meta: {
          title: "Login",
        },
      },
    ],
  },
  /*
  {
    path: "/not-found",
    component: NotFound,
    meta: {
      title: "Not Found",
    },
  },
  {
    path: "*",
    component: NotFound
  },*/
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});

export default router;
