import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ganttastic from '@infectoone/vue-ganttastic'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/sass/paper-dashboard.scss'
import '@/assets/css/themify-icons.css'

createApp(App)
  .use(router)
  .use(VueAxios, axios)
  .use(store)
  .use(ganttastic)
  .use(VueSweetalert2)
  .mount('#app')
