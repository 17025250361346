<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <!-- Utilize a classe 'd-flex' e 'justify-content-between' para alinhar os itens à esquerda e à direita -->
      <div class="d-flex justify-content-between align-items-center w-100">

        <!-- Logotipo (Brand) à esquerda -->
        <img class="navbar-brand" src="@/assets/img/logo.png" alt="Logo">

        <ul class="navbar-nav ml-auto topbar navbar-position">         
               <li><a @click="logout" href="javascript:void(0)"><i class="ti-power-off"></i></a></li>
        </ul>

      </div>
    </div>
  </nav>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
    };
  },
  methods: {
    ...mapMutations([
      'SETLOGIN',
      'SETUSER'
      ]),
    logout () {
      this.axios.post(this.$store.state.url + 'logout').then(() => {
        this.axios.defaults.headers.common['Authorization'] = ''
        localStorage.removeItem('token')
        this.SETLOGIN(0)
        this.SETUSER('')
        this.$router.push('/login')
      }, () => {
        this.axios.defaults.headers.common['Authorization'] = ''
        localStorage.removeItem('token')
        this.SETLOGIN(0)
        this.SETUSER('')
        this.$router.push('/login')
      })
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },

    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>
<style lang="scss">
.roundedNav{
  line-height: 1.6;
  margin: 15px 0px;
  padding: 10px 15px;
  opacity: 0.8;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #66615B;
  background: white;
}
.navbar-position{
  padding-right: 15px!important;
  position: relative!important;
  @media (max-width: 991px) {
    top:-10px;
  }
}
.navbar-button{
    button{
      background-color: #E3E3E3;
      border-color: #E3E3E3;
      color: #66615B;
      border-radius: 50%; 
      width: 42px;
      height: 40px;
    }
    button:hover,
    button:active,
    button:focus {
      background-color: #E3E3E3; 
      border-color: #E3E3E3; 
      color: #66615B;
    }
    i {
      font-size: 14px;
      line-height: 13px;
      left: 8px;
      top: 11px;
      position: absolute;
    }
    i:after{
      left: 4px;
      top: 1px;
      position: relative;
    }
}
</style>
