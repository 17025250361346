import { createStore } from 'vuex'

export default createStore({
  state: {
  	user: {},
  	login: false,
  	url: window.location.href.indexOf('localhost') !== -1 ? 'https://fruta.epicbit.pt/api/' : '/api/'
  },
  getters: {
  },
  mutations: {
    SETLOGIN (state, a) {
      state.login = a
    },
    SETUSER (state, a) {
      state.user = a
    }
  },
  actions: {
  },
  modules: {
  }
})